import { CancellationType, CancellationMethod } from './account_cancellation.interface'

export type AccountId = number

export enum AccountStatus {
  Unknown = 'Unknown',
  Uncommitted = 'Uncommitted',
  Active = 'Active',
  Suspended = 'Suspended',
  Closed = 'Closed',
  Terminated = 'Terminated'
}

export enum RecipientsVersion {
  V0 = 'v0',
  V05 = 'v0.5',
  V1 = 'v1',
  V15 = 'v1.5',
  V2 = 'v2'
}

export interface Account {
  account_status: AccountStatus
  automation_settings_migrated: boolean
  billing_portal_url: string
  ecm_numbers: string[]
  get_started_account_automation_completed: boolean
  get_started_completed: boolean
  id: AccountId
  is_account_verified: boolean
  is_eligible_for_reactivation: boolean
  is_free: boolean
  name: string
  recipients_migrated: boolean
  recipients_version: RecipientsVersion
  cancellation_type: CancellationType
  current_billing_period_ends_at: string
  cancellation_confirmed: boolean
  cancellation_method?: CancellationMethod
  is_eligible_for_plan_migration: boolean
  is_eligible_for_cancelled_plan: boolean
  plan_migration_target_date: string
  plan_migration_billing_target_date: string
  plan_migration_target_plan_price_in_cents: number
  plan_migration_components_overage_increase: boolean
  mfa_optional?: boolean
}
