import { EnvConfig } from './envconfig'

export const config: EnvConfig = {
  appUrl: process.env.REACT_APP_APP_URL || 'https://app.earthclassmail.com',
  apiBaseURL: process.env.REACT_APP_API_BASE_URL ?? 'https://api.dev.ecm.network',
  googleTagManagerId: process.env.REACT_APP_GTM_ID ?? '',
  connectorServiceBillcomEndpoint: process.env.CONNECTOR_SERVICE_BILLCOM_ENDPOINT ?? 'billcom_sandbox',
  launchDarklyClientSideId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '65b8c76436aa7b10aca870b1',
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY ?? '',
  hotjarId: process.env.REACT_APP_HOTJAR_ID ?? '',
  hotjarSnippetVersion: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION ?? '6',
  appCookieDomain: process.env.REACT_APP_AWS_COOKIE_DOMAIN ?? 'localhost',
  awsSecureCookie: process.env.REACT_APP_AWS_SECURE_COOKIE === 'true',
  awsProjectRegion: process.env.REACT_APP_AWS_PROJECT_REGION ?? 'us-west-2',
  awsCognitoRegion: process.env.REACT_APP_AWS_COGNITO_REGION ?? 'us-west-2',
  awsUserPoolsId: process.env.REACT_APP_AWS_POOLS_ID ?? 'us-west-2_ydAXt9yOh',
  awsUserPoolsWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ?? '5aef51m7lefnqmvti1g6khbe0l',
  awsUserPoolsMarketingClientId:
    process.env.REACT_APP_AWS_USER_POOLS_MARKETING_CLIENT_ID ?? '467a1e4somari42nm2n9130k43',
  awsOAuthDomain: process.env.REACT_APP_AWS_OAUTH_DOMAIN ?? 'auth-dev.earthclassmail.com',
  awsOAuthSignInRedirect: process.env.REACT_APP_AWS_OAUTH_SIGN_IN_REDIRECT ?? 'http://localhost:3000/login',
  awsOAuthSignOutRedirect: process.env.REACT_APP_AWS_OAUTH_SIGN_OUT_REDIRECT ?? 'http://localhost:3000/logout',
  marketingRedirectUrl:
    process.env.REACT_APP_MARKETING_REDIRECT_URL ?? 'https://staging.earthclassmail.com/checkout/billing',
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY ?? '',
  pusherCluster: process.env.REACT_APP_PUSHER_CLUSTER ?? 'mt1',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? '',
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? 'pk_test_Q1j4pTOxcBaHe9dGs4ANbFK4',
  sharePortalUrl: process.env.REACT_APP_SHARE_APP_URL || 'https://share.earthclassmail.com/',
  connectorServiceBaseUrl: process.env.REACT_APP_CONNECTOR_SERVICE_BASE_URL ?? 'https://connect.earthclassmail.com',
  datadogAppId: process.env.REACT_APP_DATADOG_APP_ID ?? '',
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? '',
  env: 'local'
}
